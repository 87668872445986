
import './App.css';

import { Route, Routes } from 'react-router-dom';

import Dashboard from './Components/Admin/Dashbord';
import AdminBlog from './Components/Admin/AdminBlog';
import CreateBlog from './Components/Admin/CreateBlog';
import UpdateBlog from './Components/Admin/UpdateBlog';
import Viewmessage from './Components/Admin/Viewmessage';
import TeamManagement from './Components/Admin/Team_management';
import Login from './Components/Admin/Login';
import Adminportfolio from './Components/Admin/Adminportfolio';
import { useEffect, useState } from 'react';
import { auth } from './firebase_con';

function App() {

  const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);

  return (
   <Routes>

{user &&<Route path='/dashboard' element={<div><Dashboard/></div>}/>}
{user &&<Route path='/adminblog' element={<div><AdminBlog/></div>}/>}
 {user &&<Route path='/createblog' element={<div><CreateBlog/></div>}/>}
 {user &&<Route path='/updateblog/:id' element={<div><UpdateBlog/></div>}/>}
  {user &&<Route path='/viewmessage' element={<div><Viewmessage/></div>}/>}
  {user &&<Route path='/teamcontrol' element={<div><TeamManagement/></div>}/>}
  {user &&<Route path='/adminportfolio' element={<div><Adminportfolio/></div>}/>}
    <Route path='/' element={<div><Login/></div>}/>

   
   </Routes>
  );
}

export default App;
