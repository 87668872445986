import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';
import TeamDelete from './TeamDelete';

function Team_management() {

    const [data, setdata] = useState({
        position:"",
        name:"",
        image:"",
      
      });
  const [respo, setrespo] = useState(null);
  
      const team=(e)=>{
  e.preventDefault()
  const formdata = new FormData()
  formdata.append('position', data.position);
  formdata.append('name', data.name);
  formdata.append('image', data.image);
  axios.post('https://backend.nxtconstructions.gov.et/createteam',formdata)
  .then(res=>{
    if(res.data.status==="success"){
  setrespo(res.data.status)
  
  window.location.reload();
  setdata({position:"",name:"",image:""
  })

    }
  })
  .catch(err=>console.log(err))
  
      }

  return (
    <div>
    <AdminHeader/>
 
    <div className=' d-flex justify-content-center align-items-center  '>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Create Team </h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully created Team Member!!</strong>
                                </div>}
      <form onSubmit={team} >
      <div className="form-group mb-2">
      <label htmlFor="image">Image </label>
      <input type="file" className="form-control  border"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
    </div>

        <div className="form-group">
          <label >Name</label>
          <input type="text" className="form-control  border" value={data.name} onChange={e => setdata({ ...data, name: e.target.value })} placeholder="Enter Name" required />
        </div>
        <div className="form-group">
          <label >Jop position</label>
          <input type="text" className="form-control  border" value={data.position} onChange={e => setdata({ ...data, position: e.target.value })} placeholder="Enter jop Position " required />
        </div>
     
    
 
       
        <button type="submit" className="btn w-100 btn-primary">Create</button>
      </form>

    </div>
  </div>

  <TeamDelete/>
    
    </div>
  )
}

export default Team_management