import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminHeader from './AdminHeader'
import axios from 'axios';

function AdminBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.nxtconstructions.gov.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
    const deleteblog=(id)=>{

        const conf=window.confirm(` Are you sure  you Want to Delete this blog? `)
        if(conf){
            axios.delete("https://backend.nxtconstructions.gov.et/deleteblog/"+id)
            .then(res=>{
              if (res.data.status==="success") {
                alert("you have seccessfully deleted the Blog!")
                window.location.reload()
                
              }
            })
            .catch(err=>console.log(err))
        }
    
    }
   

  return (
    <div>
   
    <AdminHeader/>
   
    {data.length>0 && <section id="recent-blog-posts" class="recent-blog-posts">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <h2>Blog</h2>
        <p>Delete/Update Blog Here</p>
      </header>

      <div class="row">

      {data.map((blog,i)=>{
        return   <div class="col-lg-4" key={i}>
          <div className="post-box">
          <div className="post-img"><img src={'https://backend.nxtconstructions.gov.et/images/' + blog.image} class="img-fluid" alt=""/></div>
            <span class="post-date">{blog.date}</span>
            <h3 class="post-title text-justify">{blog.subject}</h3>
            <p class="text-justify">{blog.detail}</p>
            <div className="d-flex  justify-content-center">
            <Link onClick={()=>deleteblog(blog.id)} className="btn rounded btn-dark bg-danger text-white  mr-4   " >Delete Blog</Link>
            <Link  to={`/updateblog/${blog.id}`} className="btn rounded btn-dark bg-info text-white  " >Update Blog</Link>
            </div>
          </div>
         
        </div>
      }) }
    

      </div>
     
    </div>
    
  </section>}
    
    </div>
  )
}

export default AdminBlog