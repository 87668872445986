import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';
import PortfolioDelete from './PortfolioDelete';

function Adminportfolio() {

    const [data, setdata] = useState({
        name:"",
        plink:"",
        image:"",
       type:"Web"
      });
      console.log(data.type);
  const [respo, setrespo] = useState(null);
  
      const portfolio=(e)=>{
  e.preventDefault()
  const formdata = new FormData()
  formdata.append('plink', data.plink);
  formdata.append('name', data.name);
  formdata.append('type', data.type);
  formdata.append('image', data.image);
  axios.post('https://backend.nxtconstructions.gov.et/createportfolio',formdata)
  .then(res=>{
    if(res.data.status==="success"){
  setrespo(res.data.status)
  
  window.location.reload();
  setdata({plink:"",name:"",image:"",type:""
  })

    }
  })
  .catch(err=>console.log(err))
  
      }

  return (
    <div>
    <AdminHeader/>
    
    <div className=' d-flex justify-content-center align-items-center  '>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Create Portfolio</h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully created Portfolio!!</strong>
                                </div>}
      <form onSubmit={portfolio} >
      <div className="form-group mb-2">
      <label htmlFor="image">Project Image </label>
      <input type="file" className="form-control  border"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
    </div>

        <div className="form-group">
          <label >Project Name</label>
          <input type="text" className="form-control  border" value={data.name} onChange={e => setdata({ ...data, name: e.target.value })} placeholder="Enter Project Name" required />
        </div>
        <div className="form-group">
          <label >Project Address</label>
          <input type="text" className="form-control  border" value={data.plink} onChange={e => setdata({ ...data, plink: e.target.value })} placeholder="Enter project location " required />
        </div>
       
       
        <button type="submit" className="btn w-100 btn-primary">Create</button>
      </form>

    </div>
  </div>



  <PortfolioDelete/>
    </div>
  )
}

export default Adminportfolio